import React, { Fragment, useState, useEffect } from 'react'
import PDFIcon from '../../assets/pdf.png'
import { NavLink } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getLatestGenSnap } from 'services/request/Dashboard'
import { Divider } from '@mui/material'
import ImageView from 'components/ImageView'
import { RiMessageFill } from 'react-icons/ri'
import { LoadingComponent } from 'components/LoadingComponent'

function GenSnapIntelUpdate({ dataContainer }) {
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline break-all font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    const [genSnap, setGenSnap] = useState()
    const [selectedImage, setSelectedImage] = useState(null)

    const { data, isLoading } = useQuery(
        ['getLatestGenSnap', dataContainer?.id],
        () => getLatestGenSnap(dataContainer?.id),
        {
            onSuccess: (data) => setGenSnap(data),
        }
    )

    if (isLoading) {
        return <LoadingComponent title={'Recent General SnapIntel Updates'} />
    }

    const sortedResults = genSnap?.result?.sort((a, b) => new Date(b.date) - new Date(a.date)) || []
    const latestCompany = sortedResults[0]

    return (
        <div className="w-full flex flex-col">
            <div className="w-full h-full max-h-[470px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 py-5 rounded-b-md">
                {selectedImage && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}
                {latestCompany ? (
                    <div key={latestCompany.id}>
                        <div className="w-full cursor-pointer hover:scale-105 transition-all duration-150 font-semibold text-gray-600">
                            <Divider>{latestCompany.date}</Divider>
                        </div>
                        {latestCompany.responses.map((question, questionIndex) => (
                            <Fragment key={questionIndex}>
                                <div>
                                    <button className="transition-all duration-300 px-4 py-2 w-3/4 bg-main text-white text-sm text-left rounded-md shadow-md hover:translate-x-2">
                                        {question?.question?.question}
                                    </button>
                                    <div className="w-full flex flex-col justify-end items-end my-5">
                                        <p className="animate_fade_up max-w-[75%] w-fit self-end break-words text-sm bg-white px-4 py-2 shadow-sm rounded-sm bg-opacity-70">
                                            <span className="text-xs font-bold items-end justify-end flex">
                                                {question?.user?.fullname}
                                            </span>
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {question.answer &&
                                                    makeUrlsClickable(question.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            {question?.attachments &&
                                                question?.attachments.length > 0 && (
                                                    <React.Fragment>
                                                        {question?.attachments.filter(
                                                            (attachment) =>
                                                                attachment.type === 'image'
                                                        ).length > 1 ? (
                                                            <div className="grid grid-cols-4 gap-1">
                                                                {question?.attachments
                                                                    .filter(
                                                                        (attachment) =>
                                                                            attachment.type ===
                                                                            'image'
                                                                    )
                                                                    .map((attachment) => (
                                                                        <div
                                                                            className="flex items-start justify-start w-full"
                                                                            key={attachment.id}
                                                                        >
                                                                            <img
                                                                                className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                src={
                                                                                    attachment.filename
                                                                                }
                                                                                alt="Attachment"
                                                                                onClick={() =>
                                                                                    setSelectedImage(
                                                                                        attachment.filename
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        ) : (
                                                            question?.attachments
                                                                .filter(
                                                                    (attachment) =>
                                                                        attachment.type === 'image'
                                                                )
                                                                .map((attachment) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-full"
                                                                        key={attachment.id}
                                                                    >
                                                                        <img
                                                                            className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            alt="Attachment"
                                                                            onClick={() =>
                                                                                setSelectedImage(
                                                                                    attachment.filename
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ))
                                                        )}
                                                        <div>
                                                            {question?.attachments
                                                                .filter(
                                                                    (attachment) =>
                                                                        attachment.type !== 'image'
                                                                )
                                                                .map((attachment) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-full"
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'file' ? (
                                                                            <NavLink
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="__blank"
                                                                                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                            >
                                                                                <img
                                                                                    className="w-8"
                                                                                    src={PDFIcon}
                                                                                    alt="TEXT_FILE"
                                                                                />
                                                                                <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                    {
                                                                                        attachment.filename
                                                                                    }
                                                                                </p>
                                                                            </NavLink>
                                                                        ) : null}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            <span className="text-xs mt-1 text-grey">
                                                <span>Date: </span> {question.created_at}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                ) : (
                    <div className="w-full h-full flex flex-col justify-center items-center animate_zoom_in">
                        <p className="text-4xl text-main">
                            <RiMessageFill />
                        </p>
                        <p className="text-2xl text-gray-600">No Record</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GenSnapIntelUpdate
